import axios from "axios";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import CommunicationIcon from "../../Assets/Images/communication-icon.svg";
import AnswerIcon from "../../Assets/Images/nc.png";
import Header from "../../Common/Header";
import HomeSidebar from "../../Common/HomeSidebar";
import CommonValues from "../../Common/Utils";
import SupportLoader from "../../CommonControls/SupportLoader";
// Define TypeScript interfaces for the plot data and layout
interface PlotData {
  data: Array<any>; // You can define more specific types based on your data structure
  layout: object; // You can define a more specific type based on your layout structure
}

interface ChatResponse {
  question: string;
  answer: string;
  // plotly_data: { data: {}; layout: {} };
  transactionId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
}

class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}

export default function RateAgent() {
  const [question, setQuestion] = useState("");
  const [questionloading, setQuestionLoading] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setAvatarName(localStorage.getItem("AvatarName"));
  }, []);
  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion();
      scrollToBottomForAnswer();
    }
  }, [chatResponses]);
  const scrollToBottomForQuestion = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-question-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };
  const scrollToBottomForAnswer = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-answer-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      askQuestion(question);
    }
  };

  const askQuestion = async (question: any) => {
    if (question === "") {
      return;
    }
    setQuestionLoading(true);

    const newQuestion: ChatResponse = {
      question: question,
      answer: "",
      transactionId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
    };
    setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    setQuestion("");
    scrollToBottomForQuestion();

    let chatRequest : ChatRequest = {
      ConversationId:"",
      ChatHistory:[],
      Question: question
    }

    chatResponses.forEach((response: ChatResponse) => {
      chatRequest.ChatHistory.push({
        Question: response.question,
        Answer: response.answer,
      });
    });
    chatRequest.ChatHistory.push({ Question: question, Answer: "" });

    const token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_RateAgent_Url}/query-quick-quote-v2`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(chatRequest),
    };

    axios(config)
      .then((response: any) => {
        setQuestionLoading(false);
        setQuestion("");
        const index = chatResponses.length;
        const responseData: ChatResponse = {
          ...response.data,
          transactionId: response.data.transactionId,
          question: response.data.question,
          answer: response.data.response,
        };

        setChatResponses((prevResponses: any) => {
          const updatedResponses = [...prevResponses];
          updatedResponses[index] = responseData;
          return updatedResponses;
        });
        scrollToBottomForAnswer();
      })
      .catch((error: any) => {
        setQuestionLoading(false);
        setQuestion("");
        console.error("Plotly data not found in the response");
        // if (error.response.status == 401) {
        //   CommonValues.Logout(navigate);
        // } else {
        //   toast.current.show({
        //     severity: "error",
        //     detail: "Unknown error occured in NowCerts Talk To AMS Agent",
        //     life: 3000,
        //   });
        // }
      });
  };

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const onCopyResponseClick = (copiedData: any) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };
  const onThumbsUpClick = (
    transactionId: string,
    like: boolean,
    disLike: boolean,
    feedBack: string
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const onThumbsDownClick = (
    transactionId: string,
    like: boolean,
    disLike: boolean,
    feedBack: string
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const onFeedbackSubmit = (
    transactionId: string,
    like: boolean,
    disLike: boolean,
    feedBack: string
  ) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateReaction(transactionId, like, disLike, feedBack);
  };
  const toggleFeedbackVisibility = (transactionId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };
  const onFeedbackChange = (transactionId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.transactionId === transactionId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };
  const updateReaction = (
    transactionId: string,
    like: boolean,
    disLike: boolean,
    feedBack: string
  ) => {
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: transactionId,
      Like: like,
      DisLike: disLike,
      Feedback: feedBack,
    };

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/nctalktoamsagent/nc-talk-to-ams-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.transactionId === transactionId
                ? {
                    ...response,
                    feedBack: feedBack,
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.transactionId === transactionId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response != null) {
          // if (error.response.status === 401) {
          //   CommonValues.Logout(navigate);
          // } else {
          //   toast.current.show({
          //     severity: "error",
          //     detail: "Error occured while updating feedback.",
          //     life: 3000,
          //   });
          // }
        }
      });
  };

  return (
    <div className="landing-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <HomeSidebar />
          </div>
          <div className="col d-flex justify-content-center ">
            <div
              className=" d-flex justify-content-center container w-75 position-relative "
              style={{ height: "93vh" }}
            >
              <div
                className="row w-100 "
                style={{ maxHeight: "100%", overflowY: "auto" }}
                ref={chatContainerRef}
              >
                {/* <div className="col-12 d-flex justify-content-center align-items-center">
              <div
                className="row w-100 container"
                style={{ maxHeight: "100vh", overflowY: "auto" }}
                ref={chatContainerRef}
              > */}
                {chatResponses.length > 0 ? null : (
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <h4 className=" d-flex justify-content-center align-items-center w-100">
                      How may I assist you today?
                    </h4>
                  </div>
                )}
                {chatResponses.map((response, index) => {
                  const trimmedAnswer = response.answer.trim();
                  let mainAnswer = trimmedAnswer;
                  const isLastAnswer = index === chatResponses.length - 1;
                  return (
                    <div
                      id="last-answer"
                      className={`col-12 ${
                        isLastAnswer && questionloading == false
                          ? "last-answer-block"
                          : "question-answer-block"
                      } mt-3`}
                    >
                      <div className="d-flex flex-direction-row align-items-center">
                        <span className="me-1 avtar border-rounded">
                          {avatarName}
                        </span>{" "}
                        <div className=" mb-1">
                          <b>{response.question}</b>
                        </div>
                      </div>
                      <div className="d-flex flex-direction-row">
                        <span className="me-1">
                          <img
                            src={AnswerIcon}
                            alt="Answer icon"
                            className="me-2"
                            height={28}
                          />
                        </span>
                        {isLastAnswer && questionloading ? (
                          <div
                            className={`row w-100 ${
                              isLastAnswer && questionloading
                                ? "last-question-block"
                                : "question-answer-block"
                            }`}
                          >
                            <div className="col-12">
                              <Skeleton className="mb-2"></Skeleton>
                            </div>
                            <div className="col-12">
                              <Skeleton
                                width="70%"
                                className="mb-2 d-block"
                              ></Skeleton>
                            </div>
                            <div className="col-12">
                              <Skeleton
                                width="40%"
                                className="mb-2 d-block"
                              ></Skeleton>
                            </div>
                            <div className="col-12">
                              <Skeleton
                                width="20%"
                                className="mb-2 d-block"
                              ></Skeleton>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {/* <span> {response.answer}</span> */}
                              <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                className="answer-block mt-3"
                              >
                                {response.answer}
                              </ReactMarkdown>
                            </div>
                            <div>
                              <div className="icon-box mb-2">
                                {/* <i
                                  className={
                                    response.like
                                      ? "fa fa-thumbs-up active"
                                      : "fa fa-thumbs-up"
                                  }
                                  aria-hidden="true"
                                  title="Like"
                                  onClick={() =>
                                    onThumbsUpClick(
                                      response.transactionId,
                                      !response.like,
                                      false,
                                      response.feedback
                                    )
                                  }
                                ></i>
                                <i
                                  className={
                                    response.disLike
                                      ? "dislike fa fa-thumbs-down active "
                                      : "fa fa-thumbs-down  "
                                  }
                                  aria-hidden="true"
                                  title="Dislike"
                                  onClick={() =>
                                    onThumbsDownClick(
                                      response.transactionId,
                                      false,
                                      !response.disLike,
                                      response.feedback
                                    )
                                  }
                                ></i> */}
                                <i
                                  className="fa fa-solid fa-copy"
                                  aria-hidden="true"
                                  title="Copy response"
                                  onClick={() =>
                                    onCopyResponseClick(mainAnswer)
                                  }
                                ></i>
                                {/* <i
                                  className="fa fa-comments-o"
                                  aria-hidden="true"
                                  title="Feedback"
                                  onClick={() =>
                                    toggleFeedbackVisibility(
                                      response.transactionId
                                    )
                                  }
                                ></i> */}

                                {response.showFeedback && (
                                  <div className="feedback-box mb-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <span
                                          className="close-feedback"
                                          onClick={() =>
                                            toggleFeedbackVisibility(
                                              response.transactionId
                                            )
                                          }
                                        >
                                          {" "}
                                          <i
                                            className="fa fa-close "
                                            aria-hidden="true"
                                            title="Close"
                                          ></i>
                                        </span>
                                        <h6>
                                          Your feedback will improve this
                                          product.
                                        </h6>
                                        <div className="">
                                          <textarea
                                            name="feedback"
                                            onChange={(e) =>
                                              onFeedbackChange(
                                                response.transactionId,
                                                e.target.value
                                              )
                                            }
                                            value={response.feedback}
                                            className="form-control mb-3 "
                                            rows={4}
                                            placeholder="Your feedback..."
                                          />
                                        </div>
                                        {response.feedbackLoading ? (
                                          <div className="col-12 d-flex justify-content-end">
                                            <SupportLoader />
                                          </div>
                                        ) : (
                                          <div className="col-12 text-end">
                                            <input
                                              className="btn btn-outline-info "
                                              type="button"
                                              value="Submit"
                                              onClick={() =>
                                                onFeedbackSubmit(
                                                  response.transactionId,
                                                  response.like,
                                                  response.disLike,
                                                  response.feedback
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>{" "}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="question-text">
        <div className="position-relative question-text-div">
          <div className="row">
            <div className={`col-md-3`}></div>
            <div className="col-md-6 chat-bot-text-area-left-margin">
              <textarea
                name="question"
                onChange={onQuestionChange}
                value={question}
                className="form-control question-textarea mb-2 w-100"
                rows={2}
                placeholder="Ask me about NowCerts Rate!! like to show appetite..."
                maxLength={2000}
                style={{
                  minHeight: `${Math.max(2, question.length / 50)}em`,
                }}
                onKeyDown={onEnterClick}
              />
              <span className="info-msg mb-2">
                Support Bot can make mistakes. Check important info.
              </span>
              <img
                alt="Ask"
                src={CommunicationIcon}
                onClick={() => askQuestion(question)}
                className={`communication-icon ${
                  questionloading ? "disabled" : ""
                }`}
              />
            </div>
            <div className={`col-md-3`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
